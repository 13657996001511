import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './Table.scss';
import { Button } from "react-bootstrap";
import { AppConsumer } from "../../utils/AppContext/GlobalContext";

function Table({ title, onAddFunc, onEditFunc, canAdd, data, heading }) {
    const { getPlans, state } = AppConsumer();
    const [planData, setPlanData] = useState(null);
    useEffect(() => {
        if (!planData) {
            const plans = getPlans();
            if (plans && plans.length > 0) {
                setPlanData(plans);
            }
        }
    }, [])

    const getPlanName = (slug) => {
        if (planData) {
            const plan = planData.find(a => a.slug === slug);
            if (plan) {
                return plan.title;
            }
        }
        return '';
    }

    return <div className="row table-wrapper">
        <div className="col-12">
            <h3>{title}</h3>
        </div>
        {
            canAdd && (
                <div className="col-12 table-wrapper__add-new">
                    <Button onClick={onAddFunc} size="lg">Crear</Button>
                </div>
            )
        }
        <div className="col-12">
            <table className="table table-bordered push--top w-100">
                <thead>
                    <tr>
                        {
                            heading.map(a => (
                                <th scope="col" key={`heading_${a.key}`}>{a.title}</th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                {
                    data.map(b => (
                        <tr key={`body_${b.uid}`}>
                            {
                                heading.map(c => {
                                    if (c.key === 'actions') {
                                        return (
                                            <td key={`td_${c.key}_${b.uid}`}>
                                                <Button variant="outline-primary" size='sm' onClick={() => onEditFunc(b)}>
                                                    {title !== 'Miembros Activos' ? 'Editar' : 'Información'}
                                                </Button>
                                            </td>
                                        )
                                    }
                                    if (c.key === 'membership_type') {
                                        return (
                                            <td key={`td_${c.key}_${b.uid}`}>{getPlanName(b[c.key])}</td>
                                        )
                                    }
                                    return (
                                        <td key={`td_${c.key}_${b.uid}`}>{b[c.key]}</td>
                                    )
                                })
                            }

                        </tr>
                    ))
                }
                </tbody>
            </table>
        </div>
    </div>;
}

Table.defaultProps = {
    canAdd: false
}

Table.propTypes = {
    title: PropTypes.string.isRequired,
    onEditFunc: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    heading: PropTypes.array.isRequired,
    canAdd: PropTypes.bool,
    onAddFunc: PropTypes.func
};

export default Table;