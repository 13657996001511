import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    appId: process.env.REACT_APP_APP_ID,
}

class Firebase {
    constructor() {
        app.initializeApp(config)

        /* Helper */
        this.fieldValue = app.firestore.FieldValue
        this.emailAuthProvider = app.auth.EmailAuthProvider

        /* Firebase APIs */

        this.auth = app.auth()
        this.db = app.firestore()
        this.cloud = app.functions()
    }

    /**
     * @method signIn
     * @param {*} email
     * @param {*} password
     */
    signIn = (email, password) => this.auth.signInWithEmailAndPassword(email, password)

    /**
     * @method signOut
     */
    signOut = () => this.auth.signOut()

    /**
     * @method onAuthUserListener
     * Merge Auth and DB User API
     * @param {*} next
     * @param {*} fallback
     */
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                this.user(authUser.uid)
                    .get()
                    .then((snapshot) => {
                        const dbUser = snapshot.data()

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser
                        }

                        next(authUser)
                    })
            } else {
                fallback()
            }
        })
    /**
     * @method users
     * Get Users on Authentication
     */
    users = () => this.db.collection('users')
    /**
     * @method user
     * Get User on Authentication
     * @param {uid} user id
     */
    user = (uid) => this.db.doc(`users/${uid}`)
    /**
     * @method plans
     * Get Data from firestore
     */
    plans = () => this.db.collection('plans')
    /**
     * @method plan
     * Get Data from firestore
     * @param {uid} plan id
     */
    plan = (uid) => this.db.doc(`plans/${uid}`)
    /**
     * @method counter Update
     * Get Data from firestore
     */
    invoiceCounter = () => this.db.doc('counter/q0ZhXUFQawKOSegzAZRD')
    /**
     * @method invoices
     * Get Data from firestore
     */
    invoices = () => this.db.collection('invoices')
    /**
     * @method invoice
     * Get Data from firestore
     * @param {uid} invoice id
     */
    invoice = (uid) => this.db.doc(`invoices/${uid}`)

    sendPaymentEmail = () => this.cloud.httpsCallable('sendPaymentEmail')

    tracker = () => this.db.collection('tracker')

    track = (uid) => this.db.doc(`tracker/${uid}`)

}

export default Firebase