import React, { useEffect, useContext } from 'react'
import FirebaseContext from '../Firebase/context'
import useAuthUser from './useAuthUser'
import { useHistory } from 'react-router-dom'

/**
 * withAuthorization HOC
 * Returns state and Auth context
 * @param {*} condition
 */
const withAuthorization = (condition) => (Component) => {
    function WithAuthorization(props) {
        const firebase = useContext(FirebaseContext)
        const user = useAuthUser()
        const history = useHistory()

        useEffect(() => {
            const releaseAuthListener = firebase.onAuthUserListener(
                (authUser) => {
                    if (!condition(authUser)) {
                        history.push('/')
                    }
                },
                () => {
                    history.push('/')
                }
            )

            return () => releaseAuthListener()
        }, [firebase, history])

        return condition(user) && <Component {...props} />
    }

    return WithAuthorization
}

export default withAuthorization
