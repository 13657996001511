import React, { useEffect, useState } from 'react';
import Container from "../../components/Container";
import { withAuthorization } from "../../utils/Session";
import { withFirebase } from "../../utils/Firebase";
import Header from "../../components/Header";
import { AppConsumer } from "../../utils/AppContext/GlobalContext";
import Table from "../../components/Table";
import AppModal from "../../components/Modal";

const headings = [
    {
        key: 'name',
        title: 'Nombre'
    },
    {
        key: 'email',
        title: 'Correo Electronico'
    },
    {
        key: 'phone_number',
        title: 'Numero de Telefono'
    },
    {
        key: 'membership_type',
        title: 'Plan'
    },
    {
        key: 'actions',
        title: 'Acciones'
    }
]

const Console = ({ match, firebase }) => {
    const { status } = match.params;
    const [users, setUsers] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [currentItem, setCurrentItem] = useState(null);
    const { showLoader, hideLoader, setPlanes } = AppConsumer();

    const fetchUsers = () => {
        showLoader();
        setUsers([])
        firebase.users().where('status', '==', status).get().then(snapshot => {
            let items = snapshot.docs.map(a => ({
                ...a.data(),
                uid: a.id
            }))
            if (items.length > 0) {
                firebase.plans().get().then(aSnapshot => {
                    const pItems = aSnapshot.docs.map(a => ({
                        ...a.data(),
                        uid: a.id
                    }))
                    if (pItems.length > 0){
                        setPlanes(pItems);
                    }
                    setUsers(items);
                    hideLoader();
                })
            } else {
                hideLoader();
            }

        })
    }

    useEffect(() => {
        fetchUsers()
    }, [status])

    const emptyView = () => {
        return (
            <div className="col-12 push--top text--center">
                <h5>No Encontramos Ningun Record</h5>
            </div>
        )
    }

    const getTitle = () => {
        if (status === 'in-review'){
            return 'Revision Pendiente Urgente'
        }
        if (status === 'pending-payment') {
            return 'Pago Pendiente'
        }
        return 'Miembros Activos'
    }

    const onEdit = (item) => {
        setCurrentItem(item);
        let action = 'edit';
        if (status === 'pending-payment') {
            action = 'payment'
        } else if (status === 'active') {
            action = 'info'
        }
        showModalNow(action);
    }

    const onUpdateClose = () => {
        onAddClose();
        fetchUsers();
    }

    const showModalNow = (action) => {
        setModalType(`${action}_members`);
        setShowModal(true)
    }

    const onAddClose = () => {
        setModalType('');
        setShowModal(false);
        if (currentItem) {
            setCurrentItem(null);
        }
    }

    if (users === null) {
        return <></>
    }

    return (
        <Container>
            <Header />
            {
                users.length === 0 ? emptyView() : (
                    <Table
                        title={getTitle()}
                        data={users}
                        heading={headings}
                        onEditFunc={onEdit}
                    />
                )
            }
            <AppModal
                show={showModal}
                type={modalType}
                formData={currentItem}
                handleClose={onAddClose}
                handleCloseUpdate={onUpdateClose}
            />
        </Container>
    )
};

const condition = (authUser) => !!authUser && authUser.role === 'ADMIN';

export default withAuthorization(condition)(withFirebase(Console));