import React from 'react';
import PropTypes from 'prop-types';
import { AppConsumer } from "../../utils/AppContext/GlobalContext";
import Invoice from "../Invoice";


function Review({ item, formRef, onSubmit, showInvoice }) {
    const { getPlans } = AppConsumer();

    const getPlanData = (type) => {
        const plans = getPlans();
        const plan = plans.find(a => a.slug === type);
        return plan;
    }

    const planTitle = (type) => {
        const plan = getPlanData(type)
        if (plan) {
            return plan.title;
        }
        return ''
    }

    const planCost = (type) => {
        const plan = getPlanData(type)
        if (plan) {
            return plan.cost;
        }
        return ''
    }

    const planId = (type) => {
        const plan = getPlanData(type)
        if (plan) {
            return plan.gti_id;
        }
        return ''
    }

    return (
        <div>
            <h6>Informacion del Cliente</h6>
            <div className="push--half--top">
                <label>Nombre: {item.name}</label>
            </div>
            <div>
                <label>Correo Electronico: {item.email}</label>
            </div>
            <div>
                <label>Numero de Telefono: {item.phone_number}</label>
            </div>
            <div className="push--top">
                <h6>Tipo de Membresia</h6>
            </div>
            <div className="push--half--top">
                <label>Plan: {planTitle(item.membership_type)}</label>
            </div>
            <div>
                <label>Precio: ${planCost(item.membership_type)} USD</label>
            </div>
            {
                !showInvoice && (
                    <div>
                        <label>Expiracion: {item.expires}</label>
                    </div>
                )
            }
            <div className="push--top">
                <h6>Registro de Vehiculos</h6>
            </div>
            <div className="push--half--top">
                {
                    item.registered_vehicles.map(a => (
                        <label key={a.car_plate} className="push--right">Placa: {a.car_plate}</label>
                    ))
                }
            </div>
            {
                showInvoice && (
                    <>
                        <div className="push--top">
                            <h6>Imagenes</h6>
                        </div>
                        <div className="push--half--top">
                            {
                                item.images.map((a, k) => (
                                    <a key={`image_${k}`} className="push--right" href={a} target="_blank">Imagen  #{k+1}</a>
                                ))
                            }
                        </div>
                        <Invoice
                            formRef={formRef}
                            onSubmit={onSubmit}
                            planId={planId(item.membership_type)}
                            plates={item.registered_vehicles}
                        />
                    </>
                )
            }
        </div>
    );
}

Review.propTypes = {
    item: PropTypes.object.isRequired,
    formRef: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showInvoice: PropTypes.bool.isRequired
};

export default Review;