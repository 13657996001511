import React, { useEffect, useState } from 'react';
import Container from "../../components/Container";
import { withAuthorization } from "../../utils/Session";
import { withFirebase } from "../../utils/Firebase";
import Header from "../../components/Header";
import Table from "../../components/Table";
import { AppConsumer } from "../../utils/AppContext/GlobalContext";
import { Button } from "react-bootstrap";
import AppModal from "../../components/Modal";

const headings = [
    {
        key: 'title',
        title: 'Nombre'
    },
    {
        key: 'description',
        title: 'Descripcion'
    },
    {
        key: 'cost',
        title: 'Precio USD'
    },
    {
        key: 'actions',
        title: 'Acciones'
    }
]

const Planes = ({ firebase }) => {
    const [planes, setPlanes] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [currentItem, setCurrentItem] = useState(null);
    const { showLoader, hideLoader } = AppConsumer()
    const fetchPlanes = () => {
        showLoader();
        firebase.plans().get().then(snapshot => {
            const items = snapshot.docs.map(a => ({
                ...a.data(),
                uid: a.id
            }))
            setPlanes(items);
            hideLoader();
        })
    }

    useEffect(() => {
        fetchPlanes()
    }, [])

    const onEdit = (item) => {
        setCurrentItem(item);
        showModalNow('edit');
    }

    const onUpdateClose = () => {
        onAddClose();
        fetchPlanes();
    }

    const showModalNow = (action) => {
        setModalType(`${action}_plans`);
        setShowModal(true)
    }

    const onAddClose = () => {
        setModalType('');
        setShowModal(false);
        if (currentItem) {
            setCurrentItem(null);
        }
    }

    const emptyView = () => {
        return (
            <div className="col-12 push--top text--center">
                <h5>No Encontramos ningun Plan</h5>
                <Button className="push--top" onClick={() => showModalNow('add')} size="lg">Crear Plan</Button>
            </div>
        )
    }

    return (
        <Container>
            <Header />
            {
                planes && planes.length === 0 ? emptyView() : (
                        <Table
                            title="Planes AVCR"
                            data={planes}
                            heading={headings}
                            onEditFunc={onEdit}
                            canAdd
                            onAddFunc={() => showModalNow('add')}
                        />
                    )
            }
            <AppModal
                show={showModal}
                type={modalType}
                formData={currentItem}
                handleClose={onAddClose}
                handleCloseUpdate={onUpdateClose}
            />
        </Container>
    )
};

const condition = (authUser) => !!authUser && authUser.role === 'ADMIN';

export default withAuthorization(condition)(withFirebase(Planes));