import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { withFirebase } from "../../utils/Firebase";
import { AppConsumer } from "../../utils/AppContext/GlobalContext";
import PaymentForm from "../PaymentForm";
import {generatePaymentData, userPaymentUpdate} from "../../utils/payments/paymentsUtils";


function Payment({ item, firebase, onSuccess }) {
    const { getPlans, showLoader, hideLoader } = AppConsumer();
    const [invoice, setInvoice] = useState(null);
    useEffect(() => {
        if (!invoice) {
            firebase.invoices().where("user", "==", item.uid).get()
                .then(snapshot => {
                    const query = snapshot.docs.map(a => ({
                        ...a.data(),
                        uid: a.id
                    }))
                    const b = query.find(c => c.user === item.uid);
                    setInvoice(b);
                })
        }
    }, [invoice])

    const getPlanData = (type) => {
        const plans = getPlans();
        const plan = plans.find(a => a.slug === type);
        return plan;
    }
    const planTitle = (type) => {
        const plan = getPlanData(type)
        if (plan) {
            return plan.title;
        }
        return ''
    }

    const planCost = (type) => {
        const plan = getPlanData(type)
        if (plan) {
            return plan.cost;
        }
        return ''
    }

    const submitPayment = async (values) => {
        showLoader();
       try{
           const updatePayment = {
               ...generatePaymentData(),
               ...values
           };
           const userUpdate = userPaymentUpdate();
           await firebase.invoice(invoice.uid).set(updatePayment, { merge: true });
           await firebase.user(item.uid).set(userUpdate, { merge: true });
           hideLoader();
           onSuccess();
       } catch (e) {
           console.log(e);
           hideLoader();
       }
    }

    return (
        <div>
            <h6>Informacion del Cliente</h6>
            <div className="push--half--top">
                <label>Nombre: {item.name}</label>
            </div>
            <div>
                <label>Correo Electronico: {item.email}</label>
            </div>
            <div>
                <label>Numero de Telefono: {item.phone_number}</label>
            </div>
            <div className="push--top">
                <h6>Tipo de Membresia</h6>
            </div>
            <div className="push--half--top">
                <label>Plan: {planTitle(item.membership_type)}</label>
            </div>
            <div>
                <label>Precio: ${planCost(item.membership_type)} USD</label>
            </div>
            {
                invoice && (
                    <>
                        <div className="push--top">
                            <h6>Factura</h6>
                        </div>
                        <div className="push--half--top">
                            <label>Numero: {invoice.factura}</label>
                        </div>
                        <div>
                            <label>Periodo: {invoice.periodo}</label>
                        </div>
                        <div>
                            <label>Vencimiento: {invoice.vencimiento}</label>
                        </div>
                        <div>
                            <label>Monto: ${invoice.monto}USD</label>
                        </div>
                        <PaymentForm onSubmit={submitPayment} />
                    </>
                )
            }
        </div>
    );
}

Payment.propTypes = {
    item: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired
};

export default withFirebase(Payment);