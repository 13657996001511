import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import './LoginForm.scss';
import TextInput from "../TextInput";
import { loginFormValidation } from "../../containers/Login/validationSchema";

function LoginForm({ onSubmit }) {
    return (
        <div className="login-form">
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                validationSchema={loginFormValidation}
                onSubmit={onSubmit}
            >
                {() => (
                    <Form>
                        <div className="row row--padding">
                            <div className="col-12 text--center">
                                <h2>AVCR Miembros</h2>
                            </div>
                        </div>
                        <div className="row row--padding push--top">
                            <div className="col-12">
                                <Field
                                    key="email"
                                    id="email"
                                    type="email"
                                    name="email"
                                    label="Correo Electrónico"
                                    component={TextInput}
                                    isRequired
                                />
                            </div>
                        </div>
                        <div className="row push--top row--padding">
                            <div className="col-12">
                                <Field
                                    key="password"
                                    id="password"
                                    type="password"
                                    name="password"
                                    label="Clave"
                                    component={TextInput}
                                    isRequired
                                />
                            </div>
                        </div>
                        <div className="row push--top">
                            <div className="col-12 text--center">
                                <button className="login-form__button" type="submit">
                                    Ingresar
                                </button>
                            </div>
                        </div>
                    </Form>
                )}

            </Formik>
        </div>
    );
}

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default LoginForm;