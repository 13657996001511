import { object, string } from 'yup';

export const invoiceObject = {
    fromDate: '',
    cost: '',
    plateNumber: '',
    renewDate: '',
    expirationDate: ''
}

export const formatInvoiceObject = (data) => {
    const { fromDate, cost, plateNumber, renewDate, expirationDate, planId } = data;
    return {
        periodo: fromDate.toLocaleDateString(),
        monto: cost,
        deudor: plateNumber.toUpperCase(),
        rubro: planId,
        renew: `${renewDate.getDate()}`,
        vencimiento: expirationDate.toLocaleDateString()
    }
}

export const invoiceNumberGetter = async (firebase) => {
    const snapshot = await firebase.invoiceCounter().get();
    const doc = snapshot.data();
    return `${doc.factura + 1}`;
}

export const updateInvoiceNumber = async (firebase, current) => {
    await firebase.invoiceCounter().set({
        factura: parseInt(current) + 1
    }, { merge: true })
}

export const requiredMessage = "Esta información es requerida";

export const plateValidationForm = object().shape({
    plateNumber: string()
        .trim()
        .required(requiredMessage),
});

export const invoiceValidationForm = object().shape({
    fromDate: string()
        .trim()
        .required(requiredMessage),
    cost: string()
        .trim()
        .required(requiredMessage),
    plateNumber: string()
        .trim()
        .required(requiredMessage),
    renewDate: string()
        .trim()
        .required(requiredMessage),
    expirationDate: string()
        .trim()
        .required(requiredMessage),
});
