import { object, string } from 'yup';
import { requiredMessage } from "../invoice/invoiceUtils";

export const planObject = {
    gti_id: '',
    title: '',
    description: '',
    cost: '',
    slug: ''
}

export const planValidationForm = object().shape({
    gti_id: string()
        .trim()
        .required(requiredMessage),
    title: string()
        .trim()
        .required(requiredMessage),
    description: string()
        .trim()
        .required(requiredMessage),
    cost: string()
        .trim()
        .required(requiredMessage),
    slug: string()
        .trim()
        .required(requiredMessage),
});
