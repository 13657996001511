
import React from 'react'
import { Puff } from 'svg-loaders-react'
import { AppConsumer } from '../../utils/AppContext/GlobalContext'

import './Loader.scss'

/**
 * Spinner component
 */
const Loader = () => {
    const {
        state: { isLoading }
    } = AppConsumer()

    if (!isLoading) return <></>

    return (
        <div className="loader">
            <div className="loader__wrapper">
                <Puff width={45} height={45} stroke="#fff" strokeOpacity=".125" />
            </div>
        </div>
    )
}

export default Loader