/**
 *
 * FormikSelect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { propOr } from 'ramda';
import { getIn } from 'formik';

import { styles, stylesInput, stylesInputError } from './styles';

function FormikSelect({
  isSearchable,
  id,
  type,
  options,
  disabled,
  label,
  placeholder,
  theme,
  field,
  form,
}) {

  const errors = propOr({}, 'errors')(form);
  const error = getIn(errors, type);
  const hasError = error && field.value === '';

  const getOptionValue = () => options.find(option => option.value === field.value)
      ? options.find(option => option.value === field.value)
      : '';

  const handleChange = option => form.setFieldValue(field.name, option.value);

  const DropdownIndicator = () => <span className="formik-select__triangle" />;

  const getStyles = () => {
    switch (theme) {
      case 'input':
        return hasError ? stylesInputError : stylesInput;
      default:
        return styles;
    }
  };

  return (
    <div className="text-input">
      {label && (
          <label htmlFor={type} className="text-input__label">
            {label} *
          </label>
      )}
      <ReactSelect
          isSearchable={isSearchable}
          name={type}
          id={id}
          onChange={handleChange}
          options={options}
          placeholder={placeholder}
          styles={getStyles()}
          value={getOptionValue()}
          isDisabled={disabled}
      />
      {hasError && <div className="text-input__error text-input__error-special">{error}</div>}
    </div>
  );
}

FormikSelect.defaultProps = {
  isSearchable: false,
  disabled: false,
  options: [],
  placeholder: '',
  label: '',
  theme: 'default',
};

FormikSelect.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSearchable: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'input']),
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
};

export default FormikSelect;
