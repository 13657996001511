import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import ContextProvider from "../../utils/AppContext/ContextProvider";

import Login from "../Login";
import Console from "../Console";
import Planes from "../Planes";
import Verify from "../Verify";

import Loader from "../../components/Loader";

function App() {
    const Routes = () => (
        <>
            <Route exact path="/" component={Login} />
            <Route path="/console/:status" component={Console} />
            <Route path="/planes" component={Planes} />
            <Route path="/verify" component={Verify}/>
        </>
    )

    return (
        <ContextProvider>
            <Loader />
            <Router>
                <Routes />
            </Router>
        </ContextProvider>
    );
}

export default App;
