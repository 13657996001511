import { object, string } from 'yup';
import { requiredMessage } from "../invoice/invoiceUtils";
const moment = require("moment");

export const paymentObject = {
    reference: '',
    bank: ''
}

export const bankOptions = [
    {
        value: 'bac',
        label: 'BAC San Jose'
    },
    {
        value: 'bcr',
        label: 'Banco de Costa Rica'
    },
    {
        value: 'bn',
        label: "Banco Nacional"
    }
];

export const generatePaymentData = () => {
    return {
        status: 'PAID',
        timestamp: moment().format('MM/DD/YYYY'),
        branch: "online"
    };
};

export const userPaymentUpdate = () => {
    const shouldActivate = moment().add(3, 'days').format('MM/DD/YYYY');
    return {
        status: 'pending-activation',
        activeSince: shouldActivate
    };
};


export const paymentValidation = object().shape({
    reference: string()
        .trim()
        .required(requiredMessage),
    bank: string()
        .trim()
        .required(requiredMessage)
});
