import React, { useState } from 'react';
import Container from "../../components/Container";
import { withAuthorization } from "../../utils/Session";
import { withFirebase } from "../../utils/Firebase";
import Header from "../../components/Header";
import { AppConsumer } from "../../utils/AppContext/GlobalContext";
import { Formik, Field, Form } from 'formik';
import { plateValidationForm } from "../../utils/invoice/invoiceUtils";
import TextInput from "../../components/TextInput";

import './verify.scss';
import {Button} from "react-bootstrap";
import History from "../../components/History";


const Verify = ({ firebase }) => {
    const { showLoader, hideLoader } = AppConsumer()
    const [userCheck, setUserCheck] = useState(null)

    const resetCheck = () => setUserCheck(null);

    const emptyView = () => {
        return (
            <div className="col-12 push--top text--center form-container">
                <h5>Usuario: {userCheck.code} no esta activo</h5>
                <div className="push--top">
                    <Button variant="secondary" onClick={() => resetCheck()}>Nueva Consulta</Button>
                </div>
            </div>
        )
    }

    const onSubmit = async ({ plateNumber }) => {
       const value = plateNumber.toUpperCase();
       showLoader()
       const userQuery = await firebase.tracker().where("code", "==", value).get();
        if (userQuery.docs.length === 0) {
            setUserCheck({
                code: value,
                registered: false
            });
        } else {
            const tracker = userQuery.docs.map(a => ({
                ...a.data(),
                uid: a.id
            }));
            const history = tracker.find(a => a.code === value);
            const userData = await firebase.user(history.user).get();
            const user = ({
                ...userData.data(),
                uid: userData.id
            });
            setUserCheck({
                code: value,
                registered: true,
                user: {
                    ...user,
                    history
                }
            })
        }
       hideLoader();
    }

    const trackerForm = () => (
        <div className="row">
        <div className="col-4 push--half--top form-container">
            <Formik
                initialValues={{
                    plateNumber: ''
                }}
                onSubmit={onSubmit}
                validationSchema={plateValidationForm}
            >
                {() => (
                    <Form>
                        <div className="row push--top row--padding">
                            <div className="col-12">
                                <Field
                                    key="plateNumber"
                                    id="plateNumber"
                                    type="text"
                                    name="plateNumber"
                                    label="Numero de Placa"
                                    hasWhiteBG
                                    specialError
                                    component={TextInput}
                                    isRequired
                                />
                            </div>
                        </div>
                        <div className="push--top" />
                        <Button type="submit">Verificar</Button>
                    </Form>
                )}
            </Formik>
        </div>
    </div>
    )

    const onItemUpdate = async (data) => {
        showLoader()
        const id = data.uid;
        delete data.uid;
        await firebase.track(id).set(data);
        hideLoader();
        await onSubmit({ plateNumber: userCheck.code })
    }

    return (
        <Container>
            <Header />
            <div className="col-12 verify-container">
                <h3>{!userCheck ? 'Verifica Estado de Usuario' : `Placa: ${userCheck.code}`}</h3>
                {
                    !userCheck && trackerForm()
                }
                {
                    userCheck && !userCheck.registered && emptyView()
                }
                {
                    userCheck && userCheck.registered && (
                        <>
                            <div className="push--top">
                                <Button variant="secondary" onClick={() => resetCheck()}>Nueva Consulta</Button>
                            </div>
                            <History user={userCheck.user} onUpdate={onItemUpdate} />
                        </>
                    )
                }
            </div>
        </Container>
    )
};

const condition = (authUser) => !!authUser && authUser.role === 'ADMIN';

export default withAuthorization(condition)(withFirebase(Verify));