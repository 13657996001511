import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import TextInput from "../TextInput";
import { invoiceObject, invoiceValidationForm } from "../../utils/invoice/invoiceUtils";
import DatePickerInput from "../DatePicker";
import FormikSelect from "../FormikSelect";

function Invoice({ onSubmit, formRef, planId, plates }) {

    const getInitialValues = () => {
        let plateNumber = '';
        if (plates.length === 1) {
            plateNumber = plates[0].car_plate;
        }
        return {
            ...invoiceObject,
            plateNumber,
            planId
        }
    }

    return <div className="push--top">
        <h6>Crear Cobro</h6>
        <div className="push--half--top">
            <Formik
                innerRef={formRef}
                initialValues={getInitialValues()}
                onSubmit={onSubmit}
                validationSchema={invoiceValidationForm}
            >
                {() => (
                    <Form>
                        <div className="row row--padding">
                            <div className="col-6">
                                <Field
                                    key="fromDate"
                                    id="fromDate"
                                    name="fromDate"
                                    label="Periodo"
                                    component={DatePickerInput}
                                    isRequired
                                />
                            </div>
                            <div className="col-6">
                                <Field
                                    key="expirationDate"
                                    id="expirationDate"
                                    name="expirationDate"
                                    label="Fecha de Expiracion"
                                    component={DatePickerInput}
                                    isRequired
                                />
                            </div>
                        </div>
                        <div className="row push--top row--padding">
                            <div className="col-6">
                                <Field
                                    key="renewDate"
                                    id="renewDate"
                                    name="renewDate"
                                    label="Fecha de Renovacion"
                                    component={DatePickerInput}
                                    isRequired
                                />
                            </div>
                            <div className="col-6">
                                <Field
                                    key="plateNumber"
                                    id="plateNumber"
                                    name="plateNumber"
                                    type="plateNumber"
                                    label="Numero de Placa"
                                    placeholder="Placa"
                                    options={plates.map(a => ({
                                        value: a.car_plate,
                                        label: a.car_plate
                                    }))}
                                    theme="input"
                                    component={FormikSelect}
                                />
                            </div>
                        </div>
                        <div className="row push--top row--padding">
                            <div className="col-12">
                                <Field
                                    key="cost"
                                    id="cost"
                                    type="number"
                                    name="cost"
                                    label="Cobro Total USD"
                                    hasWhiteBG
                                    specialError
                                    component={TextInput}
                                    isRequired
                                />
                            </div>
                        </div>
                        <div className="push--top" />
                    </Form>
                )}

            </Formik>
        </div>
    </div>;
}

Invoice.propTypes = {
    formRef: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    planId: PropTypes.number.isRequired,
    plates: PropTypes.array.isRequired,
};

export default Invoice;