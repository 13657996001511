import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { getIn } from 'formik';
import './TextInput.scss';

const TextInput = ({
                       field,
                       form,
                       label,
                       isRequired,
                       type,
                       hasWhiteBG,
                       specialError,
                       ...props
                   }) => {
    const name = propOr('', 'name')(field);
    const errors = propOr({}, 'errors')(form);
    const touched = propOr({}, 'touched')(form);
    const error = getIn(errors, name);
    const hasError = getIn(touched, name) && error;
    const invalidClass = hasError ? 'is-invalid' : '';

    return (
        <div className="text-input">
            {label && (
                <label htmlFor={name} className="text-input__label">
                    {label} {isRequired && '*'}
                </label>
            )}
            <input
                className={`text-input__control ${invalidClass} ${hasWhiteBG && 'text-input__white-bg'}`}
                id={name}
                type={type}
                {...field}
                {...props}
            />
            {hasError && <div className={`text-input__error ${specialError && 'text-input__error-special'}`}>{error}</div>}
        </div>
    );
};

TextInput.defaultProps = {
    label: '',
    isRequired: false,
    hasWhiteBG: false,
    specialError: false
};

TextInput.propTypes = {
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isRequired: PropTypes.bool,
    hasWhiteBG: PropTypes.bool,
    specialError: PropTypes.bool
};

export default TextInput;