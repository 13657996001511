import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import TextInput from "../TextInput";
import { planValidationForm } from "../../utils/plans/plansUtils";

function PlanForm({ formRef, initialValues, onSubmit }) {
    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={planValidationForm}
        >
            {() => (
                <Form>
                    <div className="row row--padding">
                        <div className="col-12">
                            <Field
                                key="gti_id"
                                id="gti_id"
                                type="number"
                                name="gti_id"
                                label="Codigo GTI"
                                hasWhiteBG
                                component={TextInput}
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="row push--top row--padding">
                        <div className="col-12">
                            <Field
                                key="title"
                                id="title"
                                type="text"
                                name="title"
                                label="Nombre"
                                component={TextInput}
                                hasWhiteBG
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="row push--top row--padding">
                        <div className="col-12">
                            <Field
                                key="description"
                                id="description"
                                type="text"
                                name="description"
                                label="Descripcion"
                                hasWhiteBG
                                component={TextInput}
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="row push--top row--padding">
                        <div className="col-12">
                            <Field
                                key="cost"
                                id="cost"
                                type="number"
                                name="cost"
                                label="Precio USD"
                                hasWhiteBG
                                component={TextInput}
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="row push--top row--padding">
                        <div className="col-12">
                            <Field
                                key="slug"
                                id="slug"
                                type="text"
                                name="slug"
                                label="Identificador AVCR"
                                hasWhiteBG
                                component={TextInput}
                                isRequired
                            />
                        </div>
                    </div>
                    <div className="push--top" />
                </Form>
            )}

        </Formik>
    );
}

PlanForm.propTypes = {
    formRef: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default PlanForm;