import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/Root/App';
import Firebase, { FirebaseContext } from './utils/Firebase';
import './assets/styles/main.scss';

ReactDOM.render(
  <React.StrictMode>
      <FirebaseContext.Provider value={new Firebase()}>
          <App />
      </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
