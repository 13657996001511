export const styles = {
  input: provided => ({
    ...provided,
    color: '#585858',
    padding: '0 6px',
  }),
  menu: provided => ({
    ...provided,
    border: '1px solid #000000',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: -1,
    overflow: 'hidden',
    padding: 0,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  control: (provided, { isDisabled }) => ({
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: 'none',
    display: 'inline-flex',
    fontSize: 16,
    justifyContent: 'space-between',
    label: 'control',
    opacity: isDisabled ? '0.5' : '1',
    outline: '0 !important',
    position: 'relative',
    transition: 'all 100ms',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '0 3px',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'black',
    fontSize: 14,
    margin: 0,
    maxWidth: 'initial',
    overflow: 'initial',
    padding: '0 6px 6px 0',
    position: 'static',
    textDecoration: 'underline',
    transform: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#B8B8B8',
    fontSize: 14,
    padding: '0 6px',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    ':active': {
      backgroundColor: '#f5ede1',
    },
    backgroundColor: isSelected ? '#EBEBEB' : 'transparent',
    color: 'black',
    cursor: 'pointer',
    padding: '6px 12px',
  }),
};

export const stylesInput = {
  input: provided => ({
    ...provided,
    color: '#585858',
    padding: 0,
    margin: 0,
  }),
  menu: provided => ({
    ...provided,
    border: '1px solid #000000',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: -1,
    overflow: 'hidden',
    padding: 0,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  control: (provided, { isDisabled }) => ({
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '1px solid #000000',
    display: 'inline-flex',
    fontSize: 14,
    justifyContent: 'space-between',
    label: 'control',
    opacity: isDisabled ? '0.5' : '1',
    outline: '0 !important',
    position: 'relative',
    transition: 'all 100ms',
    width: '100%',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '9px 24px',
  }),
  singleValue: provided => ({
    ...provided,
    margin: 0,
  }),
  placeholder: provided => ({
    ...provided,
    color: '#B8B8B8',
    fontSize: 14,
    padding: '0 6px',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    ':active': {
      backgroundColor: '#f5ede1',
    },
    backgroundColor: isSelected ? '#EBEBEB' : 'transparent',
    color: 'black',
    cursor: 'pointer',
    padding: '6px 12px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: '0 14px',
  }),
};

export const stylesInputError = {
  input: provided => ({
    ...provided,
    color: '#585858',
    padding: 0,
    margin: 0,
  }),
  menu: provided => ({
    ...provided,
    border: '1px solid #000000',
    borderRadius: 0,
    boxShadow: 'none',
    marginTop: -1,
    overflow: 'hidden',
    padding: 0,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  control: (provided, { isDisabled }) => ({
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: '1px solid red',
    display: 'inline-flex',
    fontSize: 14,
    justifyContent: 'space-between',
    label: 'control',
    opacity: isDisabled ? '0.5' : '1',
    outline: '0 !important',
    position: 'relative',
    transition: 'all 100ms',
    width: '100%',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '9px 24px',
  }),
  singleValue: provided => ({
    ...provided,
    margin: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: provided => ({
    ...provided,
    color: '#B8B8B8',
    fontSize: 14,
    padding: '0 6px',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    ':active': {
      backgroundColor: '#f5ede1',
    },
    backgroundColor: isSelected ? '#EBEBEB' : 'transparent',
    color: 'black',
    cursor: 'pointer',
    padding: '6px 12px',
  }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: '0 14px',
  }),
};
