import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field, Form } from 'formik';
import TextInput from "../TextInput";
import FormikSelect from "../FormikSelect";
import { bankOptions, paymentObject, paymentValidation } from "../../utils/payments/paymentsUtils";

function PaymentForm({ onSubmit }) {

    return <div className="push--top">
        <h6>Reportar Pago</h6>
        <div className="push--half--top">
            <Formik
                initialValues={paymentObject}
                onSubmit={onSubmit}
                validationSchema={paymentValidation}
            >
                {() => (
                    <Form>
                        <div className="row push--top row--padding">
                            <div className="col-6">
                                <Field
                                    key="reference"
                                    id="reference"
                                    type="text"
                                    name="reference"
                                    label="Numero de Referencia"
                                    hasWhiteBG
                                    specialError
                                    component={TextInput}
                                    isRequired
                                />
                            </div>
                            <div className="col-6">
                                <Field
                                    key="bank"
                                    id="bank"
                                    name="bank"
                                    type="bank"
                                    label="Banco"
                                    placeholder="Banco"
                                    options={bankOptions}
                                    theme="input"
                                    component={FormikSelect}
                                />
                            </div>
                        </div>
                        <div className="row push--top row--padding">
                            <button className="btn btn-primary" type="submit">Reportar</button>
                        </div>
                        <div className="push--top" />
                    </Form>
                )}

            </Formik>
        </div>
    </div>;
}

PaymentForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
};

export default PaymentForm;