import React from 'react';
import { useHistory } from 'react-router-dom';
import { withFirebase } from "../../utils/Firebase";

import './Header.scss';

function Header({ firebase }) {
    const history = useHistory();

    const onNavLink = (link) => {
        history.push(`/console/${link}`);
    }

    const logout = async () => {
        await firebase.signOut()
        await localStorage.removeItem('authUser');
        await localStorage.removeItem('plans');
        history.push('/');
    }

    const linkClass = (location) => {
        const { location: { pathname } } = history;
        if (location === pathname) {
            return 'nav-link active';
        }
        return 'nav-link';
    }

    return <div className="row header">
        <div className="col-12">
            <ul className="nav nav-tabs nav-fill">
                <li className="nav-item">
                    <div className={linkClass('/console/in-review')} onClick={() => onNavLink('in-review')}>Revision Pendiente</div>
                </li>
                <li className="nav-item">
                    <div className={linkClass('/console/pending-payment')} onClick={() => onNavLink('pending-payment')}>Pago Pendiente</div>
                </li>
                <li className="nav-item">
                    <div className={linkClass('/console/active')} onClick={() => onNavLink('active')}>Activos</div>
                </li>
                <li className="nav-item">
                    <div className={linkClass('/planes')} onClick={() => history.push('/planes')}>Planes</div>
                </li>
                <li className="nav-item">
                    <div className={linkClass('/verify')} onClick={() => history.push('/verify')}>Verificar Estado</div>
                </li>
                <li className="nav-item">
                    <div className={linkClass()} onClick={() => logout()}>Salir</div>
                </li>
            </ul>
        </div>
    </div>;
}


export default withFirebase(Header);