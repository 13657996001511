import { useEffect, useContext } from 'react'
import { FirebaseContext } from '../Firebase'
import { AppConsumer } from '../AppContext/GlobalContext'

/**
 * useAuth hook - Handles user data
 */
function useAuthUser() {
    // State

    // Get Firebase tool
    const firebase = useContext(FirebaseContext)

    //Function to set User to Context
    const {
        setUser,
        state: { user }
    } = AppConsumer()

    useEffect(() => {
        // Local storage updates when user state changes
        const releaseAuthListener = firebase.onAuthUserListener(
            (authUser) => setUser(authUser),
            () => setUser(null)
        )

        return () => releaseAuthListener()
    }, [firebase])

    return user
}

export default useAuthUser
