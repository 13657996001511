import React from 'react';
import PropTypes from 'prop-types';
import { propOr } from 'ramda';
import { getIn, useFormikContext } from 'formik';
import DatePicker, { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";


const DatePickerInput = ({
                       field,
                       form,
                       label,
                       isRequired,
                       ...props
                   }) => {
    const name = propOr('', 'name')(field);
    const errors = propOr({}, 'errors')(form);
    const touched = propOr({}, 'touched')(form);
    const error = getIn(errors, name);
    const hasError = getIn(touched, name) && error;
    const invalidClass = hasError ? 'is-invalid' : '';
    const { setFieldValue } = useFormikContext();
    registerLocale('es', es);
    return (
        <div>
            {label && (
                <label htmlFor={name} className="text-input__label">
                    {label} {isRequired && '*'}
                </label>
            )}
            <DatePicker
                {...field}
                {...props}
                locale='es'
                className={`text-input__control ${invalidClass} text-input__white-bg`}
                selected={(field.value && new Date(field.value)) || null}
                onChange={val => {
                    setFieldValue(field.name, val);
                }}
            />
            {hasError && <div className="text-input__error text-input__error-special">{error}</div>}
        </div>
    );
};

DatePickerInput.defaultProps = {
    label: '',
    isRequired: false,
    hasWhiteBG: false
};

DatePickerInput.propTypes = {
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    isRequired: PropTypes.bool,
};

export default DatePickerInput;