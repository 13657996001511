import React from 'react';
import PropTypes from 'prop-types';
import './history.scss';
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import * as moment from "moment";

function History({ user, onUpdate }) {

    const updateItem = async (type, key) => {
        const result = await Swal.fire({
            title: 'Reportar Uso de Servicio',
            text: 'Al aceptar el reporte es final y el cliente no tendra uso de este servicio',
            icon: 'warning',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Reportar',
            showCancelButton: true
        })
        if (result.isConfirmed) {
            const { history } = user;
            const needUpdate = history[type];
            const updateObject = {
                status: 'USED',
                date: moment().format('DD/MM/YYYY')
            }
            history[type] = needUpdate.map((a, k) => {
                if (k === key) {
                    return updateObject
                }
                return a
            });
            onUpdate(history)
        }
    }

    const itemCreator = (item, type, key) => {
        const isActive = item.status === 'ACTIVE';
        const hasTowing = item.distance !== undefined;
        return (
            <div key={`${type}_${key}`} className="col-3 item-wrapper">
                <label>Estado: { isActive ? 'Activo' : 'Usado'}</label>
                {
                    hasTowing && (
                        <div>
                            <label>Distancia: {item.distance} {item.unit.toUpperCase()}</label>
                        </div>
                    )
                }
                {
                    isActive && (
                        <div className="push--half--top">
                            <Button onClick={() => updateItem(type, key)}>Desactivar</Button>
                            <div className="push--half--top" />
                        </div>
                    )
                }
                {
                    !isActive && (
                        <div>
                            <label>Fecha de Uso: {item.date}</label>
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <div className="row push--top history">
            <div className="col-12">
                <h5>Informacion del Cliente</h5>
                <div className="push--half--top">
                    <label>Nombre: {user.name}</label>
                </div>
                <div>
                    <label>Numero de Telefono: {user.phone_number}</label>
                </div>
                <div>
                    <label>Activo Desde: {moment(user.activeSince).format("DD/MM/YYYY")}</label>
                </div>
                <div>
                    <label>Fecha de Expiracion: {user.expires}</label>
                </div>
                <div>
                    <label className="push--right">Vehiculos Registrados:</label>
                    {
                        user.registered_vehicles.map(a => (
                            <label key={a.car_plate} className="push--right">{a.car_plate}</label>
                        ))
                    }
                </div>
            </div>
            <div className="col-12 push--top">
                <h5>Historial de Membresia</h5>
                <div className="push--top" />
                <h6>Cambios de LLanta</h6>
                <div className="push--half--top" />
                {
                    user.history.tire && user.history.tire.map((a, k) => itemCreator(a, 'tire', k))
                }
                <div className="push--top" />
                <h6>Paso De Corriente</h6>
                <div className="push--half--top" />
                {
                    user.history.jump && user.history.jump.map((a, k) => itemCreator(a, 'jump', k))
                }
                <div className="push--top" />
                <h6>Gasolina</h6>
                <div className="push--half--top" />
                {
                    user.history.gas && user.history.gas.map((a, k) => itemCreator(a, 'gas', k))
                }
                <div className="push--top" />
                <h6>Remolque</h6>
                <div className="push--half--top" />
                {
                    user.history.towing && user.history.towing.map((a, k) => itemCreator(a, 'towing', k))
                }
            </div>
        </div>
    );
}

History.propTypes = {
    user: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default History;