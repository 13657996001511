import React from 'react';
import Swal from 'sweetalert2';

import Container from "../../components/Container";
import { withFirebase } from "../../utils/Firebase";
import LoginForm from "../../components/Login";

const authTitle = 'Authentication Error';
const authMessage = 'Please verify your credentials and try again!'
const authPassMessage = 'Invalid Password, please try again!'
const defaultMessage = 'An error occured please try again.'

const Login = (props) => {
    const { firebase, history } = props;

    const authError = (message) => {
        Swal.fire(authTitle, message, 'error');
    }

    const onSubmit = async (values) => {
        try{
            const { email, password } = values;
            await firebase.signIn(email, password);
            history.push('/console/in-review');
        } catch (err) {
            console.log(err)
            switch (err.code) {
                case 'auth/user-not-found':
                    authError(authMessage);
                    break;
                case 'auth/wrong-password':
                    authError(authPassMessage);
                    break;
                default:
                    authError(defaultMessage)
                    break;
            }
        }
    }
 return (
     <Container>
         <div className="row">
             <div className="col-12">
                 <LoginForm onSubmit={onSubmit} />
             </div>
         </div>
     </Container>
 )
};


export default withFirebase(Login);