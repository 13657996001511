import { object, string } from 'yup';

const invalidEmail = "El correo electrónico no es valido, ingresa un correo valido";
const requiredMessage = "Esta información es requerida para ingresar";

export const loginFormValidation = object().shape({
    email: string()
        .trim()
        .email(invalidEmail)
        .required(requiredMessage),
    password: string()
        .trim()
        .required(requiredMessage),
});
