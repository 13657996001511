import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import './Modal.scss';
import { Button, Modal } from "react-bootstrap";
import { planObject } from "../../utils/plans/plansUtils";
import PlanForm from "../Plans";
import { withFirebase } from "../../utils/Firebase";
import { AppConsumer } from "../../utils/AppContext/GlobalContext";
import Review from "../Review";
import Payment from "../Payment";

import { formatInvoiceObject, invoiceNumberGetter, updateInvoiceNumber } from "../../utils/invoice/invoiceUtils";

function AppModal({ type, show, handleClose, formData, firebase, handleCloseUpdate }) {
    const [typeState, setTypeState] = useState(null);
    const formRef = createRef();
    const { showLoader, hideLoader } = AppConsumer();

    const onFormData = (values) => {
        showLoader();
        if (typeState) {
            const { type: modalType, action } = typeState;
            if (modalType === 'plan') {
                if (action === 'new') {
                    firebase.plans().add(values).then(() => {
                        hideLoader();
                        handleCloseUpdate();
                    })
                } else {
                    const id = values.uid;
                    delete values.uid;
                    firebase.plan(id).set(values).then(() => {
                        hideLoader();
                        handleCloseUpdate();
                    })
                }
            }
        }
    }

    const submitPaymentForm = async (values) => {
        showLoader();
        const object = formatInvoiceObject(values);
        const invoiceNumber = await invoiceNumberGetter(firebase);
        const invoice = await firebase.invoices().add({
            ...object,
            user: formData.uid,
            factura: invoiceNumber
        })
        await updateInvoiceNumber(firebase, invoiceNumber);
        await firebase.user(formData.uid).set({
            status: 'pending-payment',
            membership_code: object.deudor,
            cost: object.monto
        }, { merge: true })
        const sendEmail = firebase.sendPaymentEmail();
        await sendEmail({ factura: invoice.id });
        hideLoader();
        handleCloseUpdate();
    }

    const onSubmit = async () => {
        if (formRef.current) {
            await formRef.current.submitForm();
        } else {
            handleClose();
        }
    }

    const getTitle = () => {
        if (type.includes('plans')){
            if (type.includes('add')){
                if (!typeState) {
                    setTypeState({ type: 'plan', action: 'new' });
                }
                return 'Crear Nuevo Plan'
            }
            if (!typeState) {
                setTypeState({type: 'plan', action: 'edit'});
            }
            return 'Editar Plan'
        }
        if (type !== ''){
            if (type.includes('payment')) {
                if (!typeState) {
                    setTypeState( { type: 'payment', action: 'edit' })
                }
                return "Actualizar Pago"
            } else if (type.includes('info')) {
                if (!typeState) {
                    setTypeState( { type: 'active', action: 'close' });
                }
                return 'Información de Miembro'
            } else if (!typeState){
                setTypeState({ type: 'sub', action: 'edit' });
            }
            return 'Actualizar Estado'
        }
    }

    const getBody = () => {
        if (typeState) {
            const { type: modalType, action } = typeState;
            if (modalType === 'plan') {
                let initalValues = planObject;
                if (action === 'edit' && formData) {
                    initalValues = formData;
                }
                return <PlanForm initialValues={initalValues} formRef={formRef} onSubmit={onFormData} />
            } else if (modalType === 'sub') {
                if (formData) {
                    return <Review item={formData} formRef={formRef} onSubmit={submitPaymentForm} showInvoice={true} />
                }
            } else if (modalType === 'payment') {
                if (formData) {
                    return <Payment item={formData} onSuccess={handleCloseUpdate} />
                }
            } else if (modalType === 'active') {
                if (formData) {
                    return <Review item={formData} formRef={formRef} onSubmit={submitPaymentForm} showInvoice={false} />
                }
            }
        }
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{getTitle()}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{getBody()}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={() => onSubmit()}>
                    {typeState && typeState.action !== 'close' ? 'Actualizar' : 'Cerrar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

AppModal.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleCloseUpdate: PropTypes.func.isRequired,
    formData: PropTypes.object,
};

export default withFirebase(AppModal);